import * as React from "react"
import { Link } from "gatsby"
import logo from "../images/logo.svg"

const Header = () => {
  return (
    <nav className="flex justify-between items-center w-full  py-4 z-50">
      <div className="px-2 ">
        <Link to="/">
          <img src={logo} alt="Logo" width="40px" />
        </Link>
      </div>
      <h3 className="text-black font-bold text-lg">
        <Link to="/about" className="highlight2">
          About
        </Link>
      </h3>
    </nav>
  )
}

export default Header
