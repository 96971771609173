import * as React from "react"

const Footer = () => {
  const externalLink = [
    {
      name: "Linkedin",
      url: "https://www.linkedin.com/in/marco-lai-0aa590177/",
    },
    { name: "GitHub", url: "https://github.com/losmarco" },
  ]
  return (
    <footer className="flex flex-row justify-between items-center w-full max-w-screen-lg py-4 ">
      <div className=" z-10 sm:w-1/4">
        {externalLink.map((link, i) => (
          <span className="pr-2  text-black font-medium md:pr-8" key={i}>
            <a href={link.url}>
              <span className="underline  md:text-lg">{link.name}</span>
              <sup className="text-xs">0{1 + i}</sup>
            </a>
          </span>
        ))}
      </div>
      <div className="hidden w-full h-0.5 bg-gray-800 z-0 sm:block" />
      <span className="text-xs text-black text-right  z-10 sm:w-1/5">
        {new Date().getFullYear()} © Marco Lai
      </span>
    </footer>
  )
}

export default Footer
