import React from "react"
import SmoothScrollbar from "smooth-scrollbar"
import OverscrollPlugin from "smooth-scrollbar/plugins/overscroll"
import Scrollbar from "react-smooth-scrollbar"
import { isBrowser, isMobile } from "react-device-detect"
import Header from "../components/header"
import Footer from "../components/footer"

const Layout = ({ location, children }) => {
  SmoothScrollbar.use(OverscrollPlugin)
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath

  return (
    <>
      {isBrowser && (
        <>
          <Scrollbar damping={0.07}>
            <div
              data-is-root-path={isRootPath}
              className=" flex justify-center h-screen "
            >
              <div className="flex flex-col px-4 lg:px-0 w-full  max-w-screen-lg selection:bg-lightorange">
                <Header />
                <main className="flex-1 z-50">{children}</main>
                <Footer />
              </div>
            </div>
          </Scrollbar>
          <div className="noise"></div>
        </>
      )}

      {isMobile && (
        <>
          <div className="noise"></div>
          <div
            data-is-root-path={isRootPath}
            className=" flex justify-center h-screen "
          >
            <div className="flex flex-col px-4 lg:px-0 w-full  max-w-screen-lg selection:bg-lightorange">
              <Header />
              <main className="flex-1 z-50">{children}</main>
              <Footer />
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default Layout
